import { FaqTagsConfig, FreshchatWidget, FreshchatWidgetConfig, UserProperties } from './Freshchat.types';
import { useSession } from 'hooks/useSession';
import { User } from 'types/User';
import freshchatConfig from './freshchatConfig';
import Script from 'next/script';
import { useEffect, useRef } from 'react';
import { useCookieConsent } from '../../hooks/useCookieConsent';
import { useApi } from '../../hooks/useApi';
import { Axios } from 'axios';

const freshChatScripts = {
  main: 'https://wchat.eu.freshchat.com/js/widget.js',
};

const vehicleTagPages = [
  'ajoneuvot-ja-tarvikkeet',
  'henkiloautot',
  'pakettiautot',
  'kevytkuorma-autot',
  'veneet',
  'moottoripyorat',
  'asuntovaunut-perakarryt',
  'moottorikelkat-ja-monkijat',
  'muut-ajoneuvot',
  'ajoneuvovaraosat',
  'ajoneuvotarvikkeet',
];

const sellerTagPages = ['yritystili', 'ilmoittaminen', 'ilmoittajaksi', 'mukaan-liittyminen'];

export const shouldDisplayWidget = (user?: User) => {
  return !user?.isAdmin;
};

export const getFaqTags = ({ pathname }: Location, user?: User | null): FaqTagsConfig => {
  const vehicleRegex = new RegExp(`^/(${vehicleTagPages.join('|')})$`);

  const shouldAddVehicleTag = !!vehicleRegex.exec(pathname);
  const shouldAddKuhuTag = /^\/kuluttajat-autohuutokauppa$/.exec(pathname);
  const shouldAddKuhu2Tag = /^\/kuluttajahuutokaupat$/.exec(pathname);
  const shouldAddApartmentTag = /^\/asunnot$/.exec(pathname);
  const shouldAddBecomeSellerTag = !user && /^\/myy-huutokaupatcomissa$/.exec(pathname);

  const tags = [
    user?.isSeller ? 'ilmoittaminen' : '',
    user?.isBuyer ? 'ostaja' : '',
    shouldAddVehicleTag ? 'ajoneuvot' : '',
    shouldAddKuhuTag || shouldAddKuhu2Tag ? 'kuluttajahuutokaupat' : '',
    shouldAddApartmentTag ? 'asunnon myynti' : '',
    shouldAddBecomeSellerTag ? 'ilmoittajaksi' : '',
  ].filter(Boolean);

  if (!tags.length) {
    return {};
  }

  return {
    tags,
    filterType: 'article',
  };
};

export const getTags = ({ pathname }: Location) => {
  const sellerRegex = new RegExp(`^/(${sellerTagPages.join('|')})$`);
  const vehicleRegex = new RegExp(`^/(${vehicleTagPages.join('|')})$`);

  const shouldAddSellerTag = !!sellerRegex.exec(pathname);
  const shouldAddVehicleTag = !!vehicleRegex.exec(pathname);

  return ['kalevi-botti', shouldAddSellerTag ? 'ilmoittajaksi' : '', shouldAddVehicleTag ? 'ajoneuvot' : ''].filter(
    Boolean
  );
};

export const getConfig = (baseConfig: FreshchatWidgetConfig, location: Location, user?: User | null) => ({
  ...baseConfig,
  faqTags: getFaqTags(location, user),
  tags: getTags(location),
  ...(user
    ? {
        externalId: user.uuid,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phoneNumber,
      }
    : {}),
});

export async function updateSessionIdentifier(client: Axios, widget: FreshchatWidget) {
  const response = await client.get('/api/chat/open');
  void setUserProperties(widget, { identifier: response.data.identifier });
}

export function updateLoggedInState(widget: FreshchatWidget, state: boolean) {
  void setUserProperties(widget, { loggedIn: state });
}

function initializeProperties(client: Axios, widget: FreshchatWidget, user?: User | null) {
  updateLoggedInState(widget, !!user);
  if (user !== undefined && widget.isOpen()) {
    void updateSessionIdentifier(client, widget);
  }
}

async function setUserProperties(widget: FreshchatWidget, properties: UserProperties) {
  const res = await widget.user.setProperties(properties);
  if (res.status !== 200) {
    throw new Error(`Failed to set user properties: ${res.status}`);
  }
}

export function getWidget() {
  return window.fcWidget;
}

export function FreshchatScript(props: { onLoad?: () => void }) {
  const {
    currentUser: { data: session },
  } = useSession();
  const { cookieConsent } = useCookieConsent();
  const { apiClient } = useApi();

  const userRef = useRef(session?.user);

  useEffect(() => {
    userRef.current = session?.user;
  }, [session?.user]);

  async function onChatLoad() {
    const widget = getWidget();
    if (widget === undefined) return console.error('Freshchat script was loaded but no fcWidget was found in window');

    const user = session?.user;

    widget.init(getConfig(freshchatConfig, location, user));
    widget.setTags(getTags(location));
    widget.setFaqTags(getFaqTags(location, user));
    initializeProperties(apiClient, widget, session?.user);
    props.onLoad && props.onLoad();

    widget.on('widget:opened', () => {
      // Use userRef.current to ensure the callback uses the latest user data
      updateLoggedInState(widget, !!userRef.current);
      if (userRef.current) {
        void updateSessionIdentifier(apiClient, widget);
      }
    });
  }

  if (!cookieConsent.freshchat) return null;

  return <Script src={freshChatScripts.main} onLoad={onChatLoad} />;
}
